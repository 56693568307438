// inc
@import "../inc/inc";

$selector: '';
@for $i from 1 through 6 {
    @if $i != 1 {
        $selector: $selector ', ';
    }
    $selector: $selector 'h#{$i}, .h#{$i}';
}


// links in titles have same color as title
#{$selector} {
    a {
        color: inherit;
    }
}

// responsive font-sizes
@include media-breakpoint-up($font-size-breakpoint) {
    body {
        /* #{round($font-size-base-desktop * 16) / 1rem}px */
        font-size: $font-size-base-desktop;
        line-height: $line-height-base-desktop;
    }

    h1, .h1 {
        /* #{round($font-size-h1-desktop * 16) / 1rem}px */
        font-size: $font-size-h1-desktop;
    }

    h2, .h2 {
        /* #{round($font-size-h2-desktop * 16) / 1rem}px */
        font-size: $font-size-h2-desktop;
    }

    h3, .h3 {
        /* #{round($font-size-h3-desktop * 16) / 1rem}px */
        font-size: $font-size-h3-desktop;
    }

    h4, .h4 {
        /* #{round($font-size-h4-desktop * 16) / 1rem}px */
        font-size: $font-size-h4-desktop;
    }

    h5, .h5 {
        /* #{round($font-size-h5-desktop * 16) / 1rem}px */
        font-size: $font-size-h5-desktop;
    }

    h6, .h6 {
        /* #{round($font-size-h6-desktop * 16) / 1rem}px */
        font-size: $font-size-h6-desktop;
    }
}
