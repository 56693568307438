/* Custom styling for application goes in this file */
html {
  overflow-y: scroll;
}

body {
  min-width: 320px;
  font-family: "VAGRoundedStd", serif;
  color: #868686;
  overflow-x: hidden;
  font-weight: 300;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 300;
}

::selection {
  background: #ee7100;
  color: #fff;
  text-shadow: none;
}

ul {
  list-style: none;
  padding: 0;
  margin: 10px 0 20px;
}

img {
  max-width: 100%;
}

button, a, a.btn-default, input, textarea, select {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
}

input, textarea, select {
  padding: 15px;
  font-size: 18px;
  line-height: 18px;
  width: 100%;
  border: 1px solid #ffffff;
  outline: none;
  margin-bottom: 25px;
}

input:focus, textarea:focus, select:focus {
  border: 1px solid #ee7100;
}

textarea {
  min-height: 133px;
}

button, a.btn-default {
  display: block;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
  border: none;
  padding: 15px 25px;
  box-sizing: border-box;
  color: #ffffff;
  background: #ee7100;
  text-align: center;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  button, a.btn-default {
    width: auto;
    display: inline-block;
    padding: 15px 45px;
  }
}

button.btn-margin-t-20, a.btn-default.btn-margin-t-20 {
  margin-top: 20px;
}

button:hover, a.btn-default:hover {
  background: #a24d00;
}

/* helper */
.responsive-grid .new-row {
  clear: left;
}

@media (min-width: 576px) {
  .responsive-grid .new-row-sm {
    clear: left;
  }
}

@media (min-width: 768px) {
  .responsive-grid .new-row-md {
    clear: left;
  }
}

@media (min-width: 1024px) {
  .responsive-grid .new-row-lg {
    clear: left;
  }
}

@media (min-width: 1250px) {
  .responsive-grid .new-row-xl {
    clear: left;
  }
}

/* fixes */
/** lightbox fix */
.fade {
  opacity: 0;
}

.fade.in {
  opacity: 1;
}

/* typo */
h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
  color: inherit;
}

@media (min-width: 1024px) {
  body {
    /* 15px */
    font-size: 0.9375rem;
    line-height: 1.3;
  }
  h1, .h1 {
    /* 30px */
    font-size: 1.875rem;
  }
  h2, .h2 {
    /* 25px */
    font-size: 1.55625rem;
  }
  h3, .h3 {
    /* 18px */
    font-size: 1.125rem;
  }
  h4, .h4 {
    /* 15px */
    font-size: 0.9375rem;
  }
  h5, .h5 {
    /* 15px */
    font-size: 0.9375rem;
  }
  h6, .h6 {
    /* 14px */
    font-size: 0.84375rem;
  }
}

/* inputs */
.form-control.no-border,
.form-control:focus.no-border {
  border-color: #fff !important;
}

/* buttons */
.btn {
  font-size: 1.125rem;
  min-width: 210px;
  font-weight: bold;
}

/* icons */
/* header */
#header {
  min-width: 320px;
}

#header .navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  vertical-align: middle;
}

#header .navbar-nav > .nav-item {
  position: relative;
}

#header .navbar-nav > .nav-item > .sub-nav-container {
  display: none;
}

#header .navbar-nav > .nav-item > .sub-nav-container > .sub-nav {
  display: block;
}

@media (min-width: 1024px) {
  #header .navbar-nav > .nav-item > .sub-nav-container {
    position: absolute;
    top: 100%;
    margin-top: 1px;
    left: 0;
    width: auto;
    display: block;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    padding-top: 0.66666667rem;
    transition: opacity 0.35s ease 0.1s, visibility 0.35s ease 0.1s, z-index 0.35s ease 0.1s;
  }
}

#header .navbar-nav > .nav-item.active > .sub-nav-container,
#header .navbar-nav > .nav-item a.active + .sub-nav-container {
  display: block;
}

@media (min-width: 1024px) {
  #header .navbar-nav > .nav-item.active > .sub-nav-container,
  #header .navbar-nav > .nav-item a.active + .sub-nav-container {
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
}

@media (min-width: 1024px) {
  #header .navbar-nav > .nav-item:hover > .sub-nav-container {
    visibility: visible;
    opacity: 1;
    z-index: auto;
  }
}

.navbar-toggler {
  position: relative;
  align-self: auto;
  vertical-align: middle;
}

.navbar-toggler:focus {
  outline: none;
}

.sx-navbar-toggle-icon {
  display: block;
  width: 2.5rem;
  height: 1.5rem;
  position: relative;
  cursor: pointer;
  transition: transform 0s ease-in-out;
  transform-origin: center;
  padding: 0.2rem 0;
}

@media (min-width: 1024px) {
  .sx-navbar-toggle-icon {
    display: none;
  }
}

.sx-navbar-toggle-icon:before,
.sx-navbar-toggle-icon > span, .sx-navbar-toggle-icon:after {
  content: '';
  display: block;
  height: 0.2rem;
  background-color: #000000;
  margin: 0;
  transition: transform 0.25s ease -0.05s, top 0.2s ease 0.25s, opacity 0.1s linear 0.25s, background-color 0.5s ease;
  transform-origin: center;
  position: absolute;
  left: 0;
  right: 0;
}

.sx-navbar-toggle-icon:before {
  top: -0.1rem;
}

.sx-navbar-toggle-icon > span {
  top: 0.65rem;
}

.sx-navbar-toggle-icon:after {
  top: 1.4rem;
}

:not(.collapsed) > .sx-navbar-toggle-icon:before,
:not(.collapsed) > .sx-navbar-toggle-icon > span, :not(.collapsed) > .sx-navbar-toggle-icon:after {
  transition: transform 0.25s ease 0.1s, top 0.2s ease 0s, opacity 0.1s linear 0s, background-color 0.4s ease 0s;
}

:not(.collapsed) > .sx-navbar-toggle-icon:before {
  transform: rotate(45deg);
  top: 0.65rem;
}

:not(.collapsed) > .sx-navbar-toggle-icon > span {
  opacity: 0;
}

:not(.collapsed) > .sx-navbar-toggle-icon:after {
  transform: rotate(-45deg);
  top: 0.65rem;
}

/* footer */
