// inc
@import "../inc/inc";

.form-control,
.form-control:focus {

    &.no-border {
        border-color: $input-bg !important;
    }
}
