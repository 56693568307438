// inc
@import "../../inc/inc";

/** lightbox fix */
.fade {
    opacity: 0;

    &.in {
        opacity: 1;
    }
}
