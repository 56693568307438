// typo
$font-size-base-desktop: 1rem/16 * 15 !default;
$line-height-base-desktop: 1.3 !default;

$font-size-h1-desktop: 2 * $font-size-base-desktop !default;
$font-size-h2-desktop: 1.66 * $font-size-base-desktop !default;
$font-size-h3-desktop: 1.2 * $font-size-base-desktop !default;
$font-size-h4-desktop: 1 * $font-size-base-desktop !default;
$font-size-h5-desktop: 1 * $font-size-base-desktop !default;
$font-size-h6-desktop: 0.9 * $font-size-base-desktop !default;

$font-size-breakpoint: 'lg' !default;

$font-size-button: 1rem/16 * 18 !default;
$font-weight-button: bold !default;


$mobile-navbar-breakpoint: 'lg' !default;

// buttons
$button-min-width: 210px;

// mobile navi toggler
$sx-navbar-toggler-color: #000000;
$sx-navbar-toggler-rotate: false;
