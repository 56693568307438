// inc
@import "../inc/inc";

#header {
    min-width: 320px;

    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: middle;
    }


    // sub navigation styling
    .navbar-nav {
        > .nav-item {
            position: relative;
            > .sub-nav-container {
                display: none;
                > .sub-nav {
                    display: block;
                }

                @include media-breakpoint-up($mobile-navbar-breakpoint) {
                    position: absolute;
                    top: 100%;
                    margin-top: 1px;
                    left: 0;
                    width: auto;
                    display: block;
                    visibility: hidden;
                    opacity: 0;
                    z-index: -1;
                    padding-top: $navbar-padding-y;
                    transition: opacity 0.35s ease 0.1s, visibility 0.35s ease 0.1s, z-index 0.35s ease 0.1s;

                }
            }

            &.active > .sub-nav-container,
            a.active + .sub-nav-container {
                display: block;

                @include media-breakpoint-up($mobile-navbar-breakpoint) {
                    visibility: hidden;
                    opacity: 0;
                    z-index: -1;
                }
            }

            &:hover {
                > .sub-nav-container {
                    @include media-breakpoint-up($mobile-navbar-breakpoint) {
                        visibility: visible;
                        opacity: 1;
                        z-index: auto;
                    }
                }
            }
        }
    }
}

.navbar-toggler {
    position: relative;
    align-self: auto;
    vertical-align: middle;

    &:focus {
        outline: none;
    }
}

.sx-navbar-toggle-icon {
    $togglerIconHeight: 1.5rem;
    $strokeWidth: 0.2rem;

    $rotation-duration: 0.35s;

    @if not $sx-navbar-toggler-rotate {
        $rotation-duration: 0s;
    }

    display: block;
    width: 2.5rem;
    height: $togglerIconHeight;
    position: relative;
    cursor: pointer;
    transition: transform $rotation-duration ease-in-out;
    transform-origin: center;
    padding: $strokeWidth 0;

    @include media-breakpoint-up($mobile-navbar-breakpoint) {
        display: none;
    }

    &:before,
    & > span,
    &:after {
        content: '';
        display: block;
        height: $strokeWidth;
        background-color: $sx-navbar-toggler-color; // todo for inverse
        margin: 0;
        transition: transform 0.25s ease ($rotation-duration - 0.05s), top 0.2s ease ($rotation-duration + 0.25s), opacity 0.1s linear ($rotation-duration + 0.25s), background-color ($rotation-duration + 0.5s) ease;
        transform-origin: center;
        position: absolute;
        left: 0;
        right: 0;
    }

    &:before {
        top: 0 - ($strokeWidth/2);
    }

    > span {
        top: ($togglerIconHeight/2) - ($strokeWidth/2);
    }

    &:after {
        top: $togglerIconHeight - ($strokeWidth/2);
    }

    :not(.collapsed) > & {
        @if $sx-navbar-toggler-rotate {
            transition: transform 0.35s ease-in-out 0.35s;
            transform: rotate(-180deg);
        }


        &:before,
        > span,
        &:after {
            transition: transform 0.25s ease 0.1s, top 0.2s ease 0s, opacity 0.1s linear 0s, background-color 0.4s ease 0s;
        }

        &:before {
            transform: rotate(45deg);
            top: ($togglerIconHeight/2) - ($strokeWidth/2);
        }

        > span {
            opacity: 0;
        }

        &:after {
            transform: rotate(-45deg);
            top: ($togglerIconHeight/2) - ($strokeWidth/2);
        }
    }
}

