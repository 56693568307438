/* Custom styling for application goes in this file */
// inc
@import "inc/inc";

html {
    overflow-y: scroll;
}

body {
    min-width: 320px;
    font-family: $font-family-main;
    color: $brand-default;
    overflow-x: hidden;
    font-weight: $font-light;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: 300;
}

::selection {
    background: $brand-primary;
    color: $white;
    text-shadow: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 10px 0 20px;
}

img {
    max-width: 100%;
}

button, a, a.btn-default, input, textarea, select {
    transition: all 0.2s;
    -moz-transition: all 0.2s;
    -webkit-transition: all 0.2s;
}

input, textarea, select {
    padding: 15px;
    font-size: 18px;
    line-height: 18px;
    width: 100%;
    border: 1px solid #ffffff;
    outline: none;
    margin-bottom: 25px;
}

input:focus, textarea:focus, select:focus {
    border: 1px solid $brand-primary;
}

textarea {
    min-height: 133px;
}

button, a.btn-default {
    display: block;
    width: 100%;
    text-transform: uppercase;
    font-size: 20px;
    border: none;
    padding: 15px 25px;
    box-sizing: border-box;
    color: #ffffff;
    background: $brand-primary;
    text-align: center;
    margin-bottom: 10px;
    @include media-breakpoint-up('md') {
        width: auto;
        display: inline-block;
        padding: 15px 45px;
    }
}

button.btn-margin-t-20, a.btn-default.btn-margin-t-20 {
    margin-top: 20px;
}

button:hover, a.btn-default:hover {
    background: $link-hover-color;
}


/* helper */
@import "sx/helper";

/* fixes */
@import "sx/fixes/lightbox-fixes";

/* typo */
@import "sx/typo";

/* inputs */
@import "sx/inputs";

/* buttons */
@import "sx/buttons";

/* icons */
@import "sx/icons";

/* header */
@import "sx/header";

/* footer */
@import "sx/footer";



////////////////////////////////////
// custom base styling goes here //
///////////////////////////////////
