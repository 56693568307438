// inc
@import "../inc/inc";


.responsive-grid {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
            .new-row#{$infix} {
                clear: left;
            }
        }
    }
}
